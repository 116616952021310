import React, { useState } from "react";
import TheNav from "./components/Header/TheNav";
import Sections from "./components/Sections/Sections";
import Cart from "./components/Cart/Cart";
import CartProvider from "./components/store/CartProvider";
// import TheFooter from "./components/Footer/TheFooter";
import About from "./pages/HeroFourSection"
import Swal from "sweetalert2";
import Media from "./pages/MediaSection"
import Elias from "./pages/Elias"
import Eldoxa from "./pages/Eldoxa"
import Purpose from "./pages/purpose"

import { NotFound } from './pages/NotFound';

// import { Switch, Route } from "react-router-dom";
// import TDOS from "./components/FreeBooks/test"
import BookDetails from "./components/BookDetails/bookDetailPage"
import Checkout from "./components/Checkout/Checkout";
import CheckoutForm from "./components/Checkout/CheckoutForm";


import Salome from "./components/SalomeDetails/bookDetailPage";
import Chronicles from "./components/ChronicleDetails/bookDetailPage";
import WhyYouAct from "./components/whyYouAct/bookDetailPage"
import WhyYouActFN from "./components/whyYouAct/bookDetailPageFN"
import WhyYouActKN from "./components/whyYouAct/bookDetailPageKN"

import GEPFN from "./components/gepFN/bookDetailPage";
import GEPKN from "./components/gepKN/bookDetailPage";
import Momma from "./components/whenMomma/bookDetailPage";
import MommaFN from "./components/whenMomma/bookDetailPageFN";
import MommaKN from "./components/whenMomma/bookDetailPageKN";

import LionPrey from "./components/lionPrey/bookDetailPage";
import LionPreyFN from "./components/lionPrey/bookDetailPageFN";
import LionPreyKN from "./components/lionPrey/bookDetailPageKN";

import Consecration from "./components/consecration/bookDetailPage";
import ConsecrationFN from "./components/consecration/bookDetailPageFN";
import ConsecrationKN from "./components/consecration/bookDetailPageKN";

import Peace from "./components/peace/bookDetailPage";
import PeaceFN from "./components/peace/bookDetailPageFN";
import PeaceKN from "./components/peace/bookDetailPageKN";


import EverythingIdontWant from "./components/EverythingIdontWant/bookDetailPage";
import EverythingIdontWantFN from "./components/EverythingIdontWant/bookDetailPageFN";
import EverythingIdontWantKN from "./components/EverythingIdontWant/bookDetailPageKN";

import WhatToDoWhileWaiting from "./components/WhatToDoWhile/bookDetailPage";
import WhatToDoWhileWaitingFN from "./components/WhatToDoWhile/bookDetailPageFN";
import WhatToDoWhileWaitingKN from "./components/WhatToDoWhile/bookDetailPageKN";

import HowToStaySane from "./components/HowToStaySane/bookDetailPage";
import HowToStaySaneFN from "./components/HowToStaySane/bookDetailPageFN";
import HowToStaySaneKN from "./components/HowToStaySane/bookDetailPageKN";


import WhyDoBadThingsHappen from "./components/BadThings/bookDetailPage";
import WhyDoBadThingsHappenFN from "./components/BadThings/bookDetailPageFN";
import WhyDoBadThingsHappenKN from "./components/BadThings/bookDetailPageKN";

import TheNeedWantDivide from "./components/NeedWant/bookDetailPage";
import TheNeedWantDivideFN from "./components/NeedWant/bookDetailPageFN";
import TheNeedWantDivideKN from "./components/NeedWant/bookDetailPageKN";

import Utterance from "./components/Utterance/bookDetailPage";
import Street from "./components/Street/bookDetailPage";
import Law from "./components/Law/bookDetailPage";
import Shadows from "./components/Shadows/bookDetailPage";
import EliasBook from "./components/Elias/bookDetailPage";
import SpiritSoulBody from "./components/SpiritSoulBody/bookDetailPage";
import AManCalledJesus from "./components/AManCalledJesus/bookDetailPage";
import WorldEvangelization from "./components/WorldEvangelisation/bookDetailPage";
import SpiritualGrowth from "./components/OYRSG/bookDetailPage";

///book/on-your-road-spiritual-growth

///book/world-evangelisation
//the-street-called-straight
//extra
// import ExploreContainer from './ExploreContainer';
// import ExploreDetail from './ExploreDetail';

import {data} from './components/testComponent/data';
import ExploreContainer from './components/testComponent/ExploreContainer';
import ExploreDetail from './components/testComponent/ExploreDetails';

import ServiceDetails from "./components/testComponent/ServiceDetails";
import ServiceList from "./components/testComponent/ServiceList";



import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";






const App = () => {

  console.log("testProps:", data)

  
  //Using useState hook
  const [cartIsShown, setCartIsShown] = useState(false);
  //END

  //Managing the state of the cart component using useState
  const onShowCartHandler = () => {
    setCartIsShown(true);
  };

  const onCloseCartHandler = () => {
    setCartIsShown(false);
  };
  //END

  //EVent to occur on Order
  const onOrderHandler = () => {
    setCartIsShown(false);

    Swal.fire({
      title: "Successful!",
      text: "Your order is being processed",
      icon: "success",
    });


  };

  //END

  //Rendering the cart and all the sections

  



  return (
    

      <BrowserRouter>
      <CartProvider>
      {cartIsShown && (
        <Cart onCloseCart={onCloseCartHandler} onOrder={onOrderHandler} />
      )}

      <TheNav onShowCart={onShowCartHandler} />

      <Routes>
      <Route path="/" element={<Sections />} />

      <Route path="/about" element={<About />} />
     <Route path="/media"  element={<Media />}/>
     <Route path="/checkout" element={<Checkout />} />
     <Route path="/checkout-form" element={<CheckoutForm />} />
     <Route path="/elias-man-of-fire" element={<Elias />} />
     <Route path="/el-doxa-GOD-of-glory" element={<Eldoxa />} />
     <Route path="/act-on-purpose" element={<Purpose />} />



     <Route path="*" element={<NotFound />} />

     <Route path="/book/:tdos" element={<BookDetails />} />
     <Route path="/book/salome" element={<Salome/>} />
     <Route path="/book/chronicles-of-faith" element={<Chronicles/>} />
     <Route path="/book/why-you-act-the-way-you-do" element={<WhyYouAct/>} />
     <Route path="/book/why-you-act-the-way-you-do-fn" element={<WhyYouActFN/>} />
     <Route path="/book/why-you-act-the-way-you-do-kn" element={<WhyYouActKN/>} />

     <Route path="/book/GEP-FN" element={<GEPFN/>} />
     <Route path="/book/GEP-KN" element={<GEPKN/>} />
     <Route path="/book/when-momma-Says-No" element={<Momma/>} />
     <Route path="/book/when-momma-Says-No-fn" element={<MommaFN/>} />
     <Route path="/book/when-momma-Says-No-kn" element={<MommaKN/>} />

     <Route path="/book/the-lion-prey" element={<LionPrey/>} />
     <Route path="/book/the-lion-prey-fn" element={<LionPreyFN/>} />
     <Route path="/book/the-lion-prey-kn" element={<LionPreyKN/>} />

     <Route path="/book/consecration" element={<Consecration/>} />
     <Route path="/book/consecration-fn" element={<ConsecrationFN/>} />
     <Route path="/book/consecration-kn" element={<ConsecrationKN/>} />

     <Route path="/book/how-to-experience-peace-in-GOD" element={<Peace/>} />
     <Route path="/book/how-to-experience-peace-in-GOD-fn" element={<PeaceFN/>} />
     <Route path="/book/how-to-experience-peace-in-GOD-Kn" element={<PeaceKN/>} />

     <Route path="/book/everything-i-dont-want-to-do" element={<EverythingIdontWant/>} />
     <Route path="/book/everything-i-dont-want-to-do-fn" element={<EverythingIdontWantFN/>} />
     <Route path="/book/everything-i-dont-want-to-do-kn" element={<EverythingIdontWantKN/>} />

     <Route path="/book/what-to-do-while-waiting" element={<WhatToDoWhileWaiting/>} />
     <Route path="/book/what-to-do-while-waiting-fn" element={<WhatToDoWhileWaitingFN/>} />
     <Route path="/book/what-to-do-while-waiting-kn" element={<WhatToDoWhileWaitingKN/>} />

     <Route path="/book/how-to-stay-sane-in-this-crazy-world" element={<HowToStaySane/>} />
     <Route path="/book/how-to-stay-sane-in-this-crazy-world-fn" element={<HowToStaySaneFN/>} />
     <Route path="/book/how-to-stay-sane-in-this-crazy-world-Kn" element={<HowToStaySaneKN/>} />

     <Route path="/book/why-do-bad-things-happen-to-good-people" element={<WhyDoBadThingsHappen/>} />
     <Route path="/book/why-do-bad-things-happen-to-good-people-fn" element={<WhyDoBadThingsHappenFN/>} />
     <Route path="/book/why-do-bad-things-happen-to-good-people-kn" element={<WhyDoBadThingsHappenKN/>} />

     <Route path="/book/the-need-want-divide" element={<TheNeedWantDivide/>} />
     <Route path="/book/the-need-want-divide-fn" element={<TheNeedWantDivideFN/>} />
     <Route path="/book/the-need-want-divide-kn" element={<TheNeedWantDivideKN/>} />


     <Route path="/book/Utterance" element={<Utterance/>} />
     <Route path="/book/the-street-called-straight" element={<Street/>} />
     <Route path="/book/the-law-and-the-doctrine-of-righteousness" element={<Law/>} />
     <Route path="/book/beyond-the-shadows" element={<Shadows/>} />
     <Route path="book/elias-man-in-prayer" element={<EliasBook/>} />
     <Route path="book/understanding-the-spirit-soul-and-body" element={<SpiritSoulBody/>} />
      <Route path="/book/a-man-called-jesus" element={< AManCalledJesus/>} />
      <Route path="/book/world-evangelisation" element={< WorldEvangelization/>} />
      <Route path="/book/on-your-road-spiritual-growth" element={< SpiritualGrowth/>} />

        {/* <Route path="explore/:name" element={<ExploreDetail products={products}/>} /> */}
{/* ///book/on-your-road-spiritual-growth
 */}

<Route exact path="/books" element={ <ServiceList />}/> 

<Route path="/ebook/:param" element={ <ServiceDetails />}/> 
        


      
    </Routes>



      </CartProvider>

      </BrowserRouter>


  );
  //END
};

export default App;
