import React from "react";
import Footer from "./Footer";

const TheFooter = () => {
  //Rendering the footer
  return (
    <footer>
      <Footer />
    </footer>
  );
  //END
};

export default TheFooter;
