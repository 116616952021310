import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./HeroThreeSection.module.css";
import HeroThreeContent from "../SectionComponents/HeroThreeContent";
import Tabs from "./Tabs";
import Panel from "./Panel";
import { events } from "./data";
import "./styles.css";
import gep from "../../asset/gep.jpg"
import gepKN from "../../asset/koreanimg/gep.jpg"

import gepFN from "../../asset/frenchimg/gep.jpg"
//The Lion's Prey - French.png
import lionFN from "../../asset/frenchimg/The Lion's Prey - French.png"
//Consecration - French.png
import consecrationFN from "../../asset/frenchimg/Consecration - French.png"
//How TEPIG - French.png
import peaceFN from "../../asset/frenchimg/How TEPIG - French.png";
import christosinpen from "../../asset/christosinpen.jpg";
import chm from "../books/CHM102_WEEK1-7.pdf";
import { saveAs } from "file-saver";
import download from "../../asset/download-btn.svg";
import tdos from "../../asset/tdos.jpg"
import tdosFN from "../../asset/frenchimg/tdos.jpg"

import BookDownloadsContent from "../SectionComponents/BookDownloadsContent";
import PaidBookDownloadsContent from "../SectionComponents/PaidBookDownloadsContent";
import faith from "../../asset/faith.jpg"
import faithFN from "../../asset/frenchimg/faith.jpg";

import salome from "../../asset/salome.jpg"
import salomeFN from "../../asset/frenchimg/salome.jpg"

import cartImage from "../../asset/cart-sm.svg";
import theWay from "../../asset/bookimg/IG6.jpg";
import theWayFN from "../../asset/frenchimg/IG6.png";
import theWayKN from "../../asset/koreanimg/Why You Act TWYD - Korean.png";

//import lionPrey from "../../asset/bookimg/";
import Consecration from "../../asset/bookimg/IG9.jpg"
import ConsecrationKN from "../../asset/koreanimg/Consecration - Korean.png";

import peace from "../../asset/bookimg/IG8.jpg";
// import peaceFN from "../../asset/frenchimg/How TEPIG - Korean.png";

import peaceKN from "../../asset/koreanimg/How TEPIG - Korean.png";

import divide from "../../asset/bookimg/IG3.jpg";
import divideKN from "../../asset/koreanimg/The Want ND - Korean.png";

import expecting from "../../asset/bookimg/IG4.jpg";
import expectingKN from "../../asset/koreanimg/What To Do WYAE - Korean.png";

import act from "../../asset/bookimg/IG6.jpg";
import actKN from "../../asset/koreanimg/Why DBTHTGP - Korean.png";

import goodPeople from "../../asset/bookimg/IG1.jpg";

import momma from "../../asset/bookimg/IG5.jpg";

import lion from "../../asset/bookimg/IG10.jpg";
import lionKN from "../../asset/koreanimg/The Lion's Prey - Korean.png";

import want from "../../asset/bookimg/IG2.jpg";
import wantKN from "../../asset/koreanimg/The Want ND - Korean.png";
import wantFN from "../../asset/frenchimg/The Want ND - French.png";

import crazy from "../../asset/bookimg/IG7.jpg";
import expectFN from "../../asset/frenchimg/What To Do WYAE - French.png";
import everythingFN from "../../asset/frenchimg/Everything IDW - French.png";
import everythingKN from "../../asset/koreanimg/Everything IDW - Korean.png";

import saneFN from "../../asset/frenchimg/SSITCW.png";
import saneKN from "../../asset/koreanimg/How To SSITCW - Korean.png";

import mommaFN from "../../asset/frenchimg/When Momma Says No - French.png";
import mommaKN from "../../asset/koreanimg/When Momma Says No - Korean.png";

import badFN from "../../asset/frenchimg/Why DBTHTGP - French.png";
import badKN from "../../asset/koreanimg/Why DBTHTGP - Korean.png";
import dataApi from "../../components/testComponent/servicedata";

import bookServices from "../../components/testComponent/ServiceList";
import bookService from "../../components/testComponent/Services"

//What To Do WYAE - French.png
//Everything IDW - French.png
//How To SSITCW - French.png
import COF from "../../asset/Chronicles of Faith - EN.jpg"
import servicedata from "../../components/testComponent/servicedata";

import bts from "../../asset/bookimg/bts.jpg";
import street from "../../asset/bookimg/streetcalledStraight.jpg";
import tlador from "../../asset/bookimg/tlador.jpg";
import Utterance from "../../asset/bookimg/utterance.jpg";


import Elias from "../../asset/bookimg/elias-mip.jpeg";
import MCJ from "../../asset/bookimg/A Man Called Jesus.jpg";
import OYSG from "../../asset/bookimg/On Your Road To Spiritual Growth.jpg";
import SSB from "../../asset/bookimg/Understanding the Spirit, Soul and Body 2.jpg";
import WE from "../../asset/bookimg/World Evangelisation.jpg";


//frontend/src/asset/bookimg/Understanding the Spirit, Soul and Body 2.jpg
///Users/dehoni/myVanguard/frontend/src/asset/bookimg/Spirit, Soul and Body (1).jpg
///Users/dehoni/Downloads/World Evangelisation.jpg
///Users/dehoni/myVanguard/frontend/src/asset/bookimg/Understanding the Spirit, Soul and Body.jpg
///Users/dehoni/myVanguard/frontend/src/asset/bookimg/On Your Road To Spiritual Growth.jpg
///Users/dehoni/myVanguard/frontend/src/asset/bookimg/elias-mip.jpeg
//frontend/src/asset/bookimg/A Man Called Jesus.jpg
//Data objects to be used in the HeroThree section for rendering dishes information
// const dummyListone = [
//   {
//     id: "d1",
//     src: gep,
//     name: "God's Eternal Plan",
//     price: 0,
//     cart: cartImage,
//     route: "gep"
//     // download: "download"
//   },

//   {
//     id: "d2",
//     src: gep,
//     name: "God's Eternal Plan",
//     price: 3000,
//     cart: cartImage

//   },

//   {
//     id: "d3",
//     src: gep,
//     name: "God's Eternal Plan",
//     price: 3000,
//     cart: cartImage

//   },

//   {
//     id: "d4",
//     src: gep,
//     name: "GOd's Eternal Plan",
//     price: 3000,
//     cart: cartImage

//   },
// ];



// const bookPaid = [
//   {

//       id: "d1",
//       src: gep,
//       name: "GOD's Eternal Plan",
//       download: "Download",
//       downloadclick: download,
//       readmore: "gep",
//       pdf: "Consecration - Christosin Olalere.pdf"
    
//   }
// ];

const bookStore = [
  
  {
    id: "d11",
    src: MCJ,
    name: "A Man Called Jesus",
    download: "Download",
    downloadclick: download,
    readmore: "/book/a-man-called-jesus",
    pdf: "#"
  },

  {
    id: "d9",
    src: Elias,
    name: "ELIAS - Man in Prayer",
    download: "Download",
    downloadclick: download,
    readmore: "/book/elias-man-in-prayer",
    pdf: "#"
  },
  {
    id: "d10",
    src: SSB,
    name: "Understanding the Spirit, Soul and Body",
    download: "Download",
    downloadclick: download,
    readmore: "book/understanding-the-spirit-soul-and-body",
    pdf: "#"
  },



  {
    id: "d12",
    src: WE,
    name: "World Evangelisation",
    download: "Download",
    downloadclick: download,
    readmore: "/book/world-evangelisation",
    pdf: "#"
  },

  {
    id: "d13",
    src: OYSG,
    name: "On Your Road to Spiritual Growth",
    download: "Download",
    downloadclick: download,
    readmore: "/book/on-your-road-spiritual-growth",
    pdf: "#"
  },
  {
    id: "d1",
    src: Utterance,
    name: "Utterance",
    download: "Download",
    downloadclick: download,
    readmore: "book/utterance",
    pdf: "#"
  },

  {
    id: "d2",
    src: street,
    name: "The Street Called Straight",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-street-called-straight",
    pdf: "#"
  },

  {
    id: "d3",
    src: tlador,
    name: "The Law and The Doctrine of Righteousness",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-law-and-the-doctrine-of-righteousness",
    pdf: "#"
  },

  {
    id: "d4",
    src: bts,
    name: "Beyond the Shadows",
    download: "Download",
    downloadclick: download,
    readmore: "/book/beyond-the-shadows",
    pdf: "#"
  },
  {
    id: "d5",
    src: tdos,
    name: "TDOS & TFOS",
    download: "Download",
    downloadclick: download,
    readmore: "book/tdos-tfos",
    pdf: "#"
  },

  {
    id: "d6",
    src: salome,
    name: "Salome",
    download: "Download",
    downloadclick: download,
    readmore: "/book/salome",
    pdf: "#"
  },

  {
    id: "d7",
    src: COF,
    name: "Chronicles of Faith",
    download: "Download",
    downloadclick: download,
    readmore: "/book/chronicles-of-faith",
    pdf: "#"
  },





  

  
  


 ]



 
 const bookStoreFN = [


  {
    id: "d0",
    src: gepFN,
    name: "Le plan éternel de DIEU",
    download: "Download",
    downloadclick: download,
    readmore: "/book/GEP-FN",
    pdf: "#"
  },

  {
    id: "d1",
    src: theWayFN,
    name: "Pourquoi tu agis comme tu le fais",
    download: "Download",
    downloadclick: download,
    readmore: "/book/why-you-act-the-way-you-do-fn",
    pdf: "#"
  },

  {
    id: "d2",
    src: lionFN,
    name: "La proie du lion",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-lion-prey-fn",
    pdf: ""
  },

  {
    id: "d3",
    src: consecrationFN,
    name: "Consécration",
    download: "Download",
    downloadclick: download,
    readmore: "/book/consecration-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  
  

 ]


 const bookStore2FN = [
  {
    id: "d1",
    src: expectFN,
    name: "Que faire en attendant",
    download: "Download",
    downloadclick: download,
    readmore: "/book/what-to-do-while-waiting-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d2",
    src: everythingFN,
    name: "Tout ce que je ne veux pas",
    download: "Download",
    downloadclick: download,
    readmore: "/book/everything-i-dont-want-to-do-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d4",
    src: mommaFN,
    name: "Quand maman dit non",
    download: "Download",
    downloadclick: download,
    readmore: "/book/when-momma-Says-No-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "10",
    src: wantFN,
    name: "Le fossé besoin-vouloir",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-need-want-divide-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },



 ]

 
 const bookStoreKN = [
  {
    id: "d1",
    src: gepKN,
    name: "하나님의 영원한 계획",
    download: "Download",
    downloadclick: download,
    readmore: "/book/GEP-KN",
    pdf: "#"
  },

  {
    id: "d1",
    src: theWayKN,
    name: "왜 그렇게 행동하는가",
    download: "Download",
    downloadclick: download,
    readmore: "/book/why-you-act-the-way-you-do-kn",
    pdf: "#"
  },

  {
    id: "d2",
    src: lionKN,
    name: "사자의 먹이",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-lion-prey-kn",
    pdf: ""
  },

  {
    id: "d3",
    src: ConsecrationKN,
    name: "헌신",
    download: "Download",
    downloadclick: download,
    readmore: "/book/consecration-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d4",
    src: expectingKN,
    name: "아직 기대하면서해야 할 일",
    download: "Download",
    downloadclick: download,
    readmore: "/book/what-to-do-while-waiting-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d5",
    src: everythingKN,
    name: "내가 원하지 않는 모든 것",
    download: "Download",
    downloadclick: download,
    readmore: "/book/everything-i-dont-want-to-do-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d6",
    src: saneKN,
    name: "이 미친 세상에서 제정신을 유지하는 방법",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-stay-sane-in-this-crazy-world-Kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d7",
    src: mommaKN,
    name: "엄마가 거절할 때",
    download: "Download",
    downloadclick: download,
    readmore: "/book/when-momma-Says-No-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d8",
    src: badKN,
    name: "착한 사람에게 나쁜 일이 일어나는 이유",
    download: "Download",
    downloadclick: download,
    readmore: "/book/why-do-bad-things-happen-to-good-people-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d9",
    src: peaceKN,
    name: "하나님의 평화를 경험하는 방법",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-experience-peace-in-GOD-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },
  {
    id: "10",
    src: wantKN,
    name: "필요-필요 분할",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-need-want-divide-kn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  }

  // {
  //   id: "d2",
  //   src: salome,
  //   name: "Salome",
  //   download: "Download",
  //   downloadclick: download,
  //   readmore: "Read More",
  //   pdf: ""
  // },

  // {
  //   id: "d3",
  //   src: COF,
  //   name: "Chronicles of Faith",
  //   download: "Download",
  //   downloadclick: download,
  //   readmore: "Read More",
  //   pdf: "#"
  // },


  

 ]

 
 

 const bookStore2 = [

  {
    id: "d1",
    src: theWay,
    name: "Why you act the way you do",
    download: "Download",
    downloadclick: download,
    readmore: "/book/why-you-act-the-way-you-do",
    pdf: "#"
  },

  {
    id: "d2",
    src: lion,
    name: "The Lion's Prey",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-lion-prey",
    pdf: ""
  },

  {
    id: "d3",
    src: Consecration,
    name: "Consecration",
    download: "Download",
    downloadclick: download,
    readmore: "/book/consecration",
    pdf: ""
  },

  {
    id: "d4",
    src: peace,
    name: "How to experience Peace in God",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-experience-peace-in-GOD",
    pdf: ""
  },

  

 ]


 

 const bookStore3 = [
  // {
  //   id: "d1",
  //   src: divide,
  //   name: "The Want-Need Divide",
  //   download: "Download",
  //   downloadclick: download,
  //   readmore: "Read More",
  //   pdf: "#"
  // },

  {
    id: "d2",
    src: want,
    name: "Everything I don't want",
    download: "Download",
    downloadclick: download,
    readmore: "/book/everything-i-dont-want-to-do",
    pdf: ""
  },

  {
    id: "d3",
    src: expecting,
    name: "What to do while you are expecting",
    download: "Download",
    downloadclick: download,
    readmore: "/book/what-to-do-while-waiting",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },
  {
    id: "d1",
    src: crazy,
    name: "How to stay sane in this crazy world",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-stay-sane-in-this-crazy-world",
    pdf: "#"
  },

  {
    id: "d2",
    src: goodPeople,
    name: "Why do bad things happen to good people?",
    download: "Download",
    downloadclick: download,
    readmore: "book/why-do-bad-things-happen-to-good-people",
    pdf: ""
  },

  {
    id: "d1",
    src: divide,
    name: "The Want-Need Divide",
    download: "Download",
    downloadclick: download,
    readmore: "/book/the-need-want-divide",
    pdf: "#"
  },
  {
    id: "d8",
    src: momma,
    name: "When Momma says No",
    download: "Download",
    downloadclick: download,
    readmore: "/book/when-momma-Says-No",
    pdf: "#"
  },
  // {
  //   id: "d3",
  //   src: act,
  //   name: "Why you act the way you do",
  //   download: "Download",
  //   downloadclick: download,
  //   readmore: "Read More",
  //   pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  // },

 ]

 //French3
 const bookStore3FN = [
  
 
  {
    id: "d3",
    src: saneFN,
    name: "Comment rester sain d'esprit dans ce monde fou",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-stay-sane-in-this-crazy-world-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d9",
    src: peaceFN,
    name: "Comment faire l'expérience de la paix en DIEU",
    download: "Download",
    downloadclick: download,
    readmore: "/book/how-to-experience-peace-in-GOD-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d8",
    src: badFN,
    name: "Pourquoi les mauvaises choses arrivent aux bonnes personnes",
    download: "Download",
    downloadclick: download,
    readmore: "/book/why-do-bad-things-happen-to-good-people-fn",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  }

  
 ]

 const bookStore4 = [
  

  // {
  //   id: "d3",
  //   src: expecting,
  //   name: "What to do while Expecting",
  //   download: "Download",
  //   downloadclick: download,
  //   readmore: "Read More",
  //   pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  // },



 ]
//END
//HeroThreeSection_dish_col__X0AVj col-lg-3
const HeroThreeSection = () => {

 

  //Mapping the data from the object above and storing them in a variable
  const dishList1 = servicedata.map((dish) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        {/* <HeroThreeContent
          key={dish.id}
          id={dish.id}
          name={dish.name}
          src={dish.src}
          price={dish.price}
          download={dish.download}
          cart={dish.cart}
          route={dish.route}
        /> */}

       
      </div>
    </Col>
  ));

  const bookList1 = bookStore.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));

  // const bookPaid = paidStore.map((book) => (
  //   <Col lg={3} className={classes.dish_col}>
  //     <div
  //       data-aos="fade-up"
  //       data-aos-easing="ease-out"
  //       data-aos-duration="700"
  //     >
  //       <PaidBookDownloadsContent
  //         key={book.id}
  //         id={book.id}
  //         name={book.name}
  //         src={book.src}
  //         download={book.download}
  //         downloadclick={book.downloadclick}
  //         readmore={book.readmore}
  //         pdf={book.pdf}
  //       />
  //     </div>
  //   </Col>
  // ));


  //bookStoreFN
  const bookList1FN = bookStoreFN.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));


  const bookList2FN = bookStore2FN.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));
  //bookStore2FN
  
  const bookList2 = bookStore2.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));



  const bookList3 = bookStore3.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));


  //BookStore3FN
  const bookList3FN = bookStore3FN.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));

  const bookList4 = bookStore4.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));

  const bookList5 = bookStoreKN.map((book) => (
    <Col lg={3} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookDownloadsContent
          key={book.id}
          id={book.id}
          name={book.name}
          src={book.src}
          download={book.download}
          downloadclick={book.downloadclick}
          readmore={book.readmore}
          pdf={book.pdf}
        />
      </div>
    </Col>
  ));


  // const dishList2 = dummyListtwo.map((dish) => (
  //   <Col lg={4} className={classes.dish_col}>
  //     <div
  //       data-aos="fade-up"
  //       data-aos-easing="ease-out"
  //       data-aos-duration="700"
  //     >
  //       <HeroThreeContent
  //         key={dish.id}
  //         id={dish.id}
  //         name={dish.name}
  //         src={dish.src}
  //         price={dish.price}
  //       />
  //     </div>
  //   </Col>
  // ));
  //END

  //Rendering the Hero Three section
  return (
    <section id="dishes">
      <Container>
        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Col xs={12}>
            <div className={classes.header_div}>
              <h2>Pastor's Books</h2>
            </div>
          </Col>
        </Row>

         {/* <Row className={classes.row_dish}>{dishList1}</Row>  */}

        {/* <Row>{dishList2}</Row> */}
        <Tabs>
      <Panel title="English">
      {/* <Row lassName={classes.row_dish}>{dishList1}</Row> */}
      {/* <Row className={classes.row_dish}>{bookPaid}</Row> */}
      <Row className={classes.row_dish}>{bookList1}</Row>
        <Row className={classes.row_dish}>{bookList2}</Row> 
        <Row className={classes.row_dish}>{bookList3}</Row> 
        <Row className={classes.row_dish}>{bookList4}</Row> 

      </Panel>

      <Panel title="French">
      <Row className={classes.row_dish}>{bookList1FN}</Row>
      <Row className={classes.row_dish}>{bookList2FN}</Row>
      <Row className={classes.row_dish}>{bookList3FN}</Row>

      </Panel>

      <Panel title="Korean">
      <Row className={classes.row_dish}>{bookList5}</Row>
     </Panel>
     
    </Tabs>
      </Container>
    </section>
  );
  //END
};

export default HeroThreeSection;
