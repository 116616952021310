import React, { useState } from "react";
import { Spinner } from 'react-bootstrap'; // Import Spinner for loading indication
import classes from "./BookContent.module.css";

const BookContent = (props) => {
  const [isDownloading, setIsDownloading] = useState(false); // State to track download status

  const handleDownload = (e) => {
    e.preventDefault(); // Prevent default action
    setIsDownloading(true); // Set loading state to true

    const link = document.createElement('a');
    link.href = props.pdf; // Ensure this prop is correctly set to the PDF URL
    link.download = "ON YOUR ROAD TO SPIRITUAL GROWTH.pdf"; // Specify the name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link after triggering download

    setIsDownloading(false); // Reset loading state after triggering download
  };

  return (
    <div className={classes.dish_content}>
      <div className={classes.dish_image_div}>
        <img className={classes.book_img_div} src={props.src} alt="Books" />
      </div>

      <div className={classes.dish_text_div}>
        <p>{props.name}</p>
      </div>

      <div className={classes.dish_download_div}>
        <p>{props.download}</p>
      </div>

      <div className={classes.dish_price_div}>
        <p className="my-auto">{props.free}</p>
      </div>

      <div className={classes.desc_container}>
        <h2 className={classes.dish_desci_div}>{props.description}</h2>
      </div>

      {isDownloading ? ( // Show loading spinner if downloading
        <Spinner animation="border" className={classes.spinner} />
      ) : (
        <button className={classes.download_btn} onClick={handleDownload}>
          Download for free <i className="bi bi-cloud-download"></i>
        </button>
      )}
    </div>
  );
};

export default BookContent;
